import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import placeholder from '../../images/tmp/caseStudySplash.jpg';
import linkStripper from '../../utils/link-stripper';
import convertHtml from '../../utils/convertHtml';

//Import Components
import Label from '../../components/Label';
import BlankSpace from '../../components/blankSpace';
import NumberBlock from '../../components/numberBlock';
import ButtonNotUnderlined from '../../components/ButtonNotUnderlined';

const ButtonNotUnderlinedContainer = styled.div`
  div {
    color: #fff !important;
  }
  svg {
    fill: #fff !important;
  }
`;

const SplashContainer = styled.div`
  width: 100%;
  display: flex;
  font-family: 'DM Sans', sans-serif;
  position: relative;
  overflow: hidden;
  @media (max-width: 900px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`;

const ColourContainer = styled.div`
  background-color: #4758eb;
  height: 80%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: -1;
  @media (max-width: 1050px) {
    height: 660px;
  }
  @media (max-width: 950px) {
    height: 100%;
    padding-bottom: 50px;
  }
`;

const RightContent = styled.div`
  width: 50%;
  margin-top: 75px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 250px;
  @media (max-width: 950px) {
    justify-content: center;
    padding-bottom: 0px;
  }
  @media (max-width: 900px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 0px;
    justify-content: flex-start;
  }
`;

const LeftContent = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  @media (max-width: 900px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 50px;
  }
`;

const TitleText = styled.h2`
  font-size: 40px;
  line-height: 48px;
  color: #fff;
  font-family: DM Serif Text;
  font-style: normal;
  max-width: 500px;
  font-weight: 400;
  margin-bottom: 60px;
  @media (max-width: 1050px) {
    font-size: 30px;
    line-height: 38px;
  }
  @media (max-width: 600px) {
    margin-bottom: 30px;
  }
`;

const SplashImage = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 900px) {
    width: 100%;
    margin-top: -200px;
    display: none;
  }
`;

const SplashImg = styled.img`
  height: 655px;
  width: 450px;
  margin-top: 80px;
  @media (max-width: 1050px) {
    height: 490px;
    width: 335px;
  }
`;

const TextContentContainer = styled.div`
  @media (max-width: 600px) {
    padding-top: 40px;
  }
`;

const NumberBlockContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-right: 10px;
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

function leftTextRightImage({
  props,
  label_text,
  label_colour,
  title_text,
  cta_text,
  cta_link,
  cta_colour,
  cta_arrow_colour,
  case_study_image,
  case_study_figure,
  case_study_figure_type,
  cta_link_target,
  case_study_subtitle,
  items,
}) {
  return (
    <SplashContainer>
      <LeftContent>
        <SplashImage>
          <SplashImg src={case_study_image} />
        </SplashImage>
      </LeftContent>
      <RightContent>
        <TextContentContainer>
          <Label text={label_text} color={label_colour} />
          <BlankSpace height="40px" />
          <TitleText dangerouslySetInnerHTML={convertHtml(title_text)} />

          <NumberBlockContainer>
            {items &&
              items.map((item, index) => (
                <NumberBlock
                  key={index}
                  number={item.case_study_figure.text}
                  statType={item.case_study_figure_type.text}
                  numberSubtitle={item.case_study_subtitle.text}
                  color={'#ffffff'}
                  statFontSize={'58px'}
                  statFontLineHeight={'36px'}
                  fontWeight={'normal'}
                  padding={'20px 0px 0px 0px'}
                  borderStyle={'none'}
                  borderStyle={'solid'}
                  borderColor={'transparent'}
                  statFontSize={'100px'}
                  statFontLineHeight={'80px'}
                  fontWeight={'bold'}
                />
              ))}
          </NumberBlockContainer>
          <BlankSpace height="60px" mobileHeight="0px" />
          <ButtonNotUnderlinedContainer>
            <ButtonNotUnderlined
              buttonText={cta_text}
              color="#FFF"
              link={linkStripper(cta_link)}
              arrowColor={cta_arrow_colour}
              target={cta_link_target}
            />
          </ButtonNotUnderlinedContainer>
        </TextContentContainer>
      </RightContent>
      <ColourContainer></ColourContainer>
    </SplashContainer>
  );
}

export default leftTextRightImage;
