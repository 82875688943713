import React, { Component } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';

import placeholder from '../images/tmp/explanationImage.png';

//Import Components
import LazyImage from '../components/lazy-image';
import Label from '../components/Label';
import BlankSpace from '../components/blankSpace';
import Squiggle from '../components/squiggle';

const TextContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const TextContainerInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;

  @media (max-width: 860px) {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: 'DM Sans', sans-serif;
  min-height: 670px;
  overflow: hidden;
  width: 100%;
  @media (max-width: 800px) {
    flex-wrap: wrap;
    height: fit-content;
    padding-bottom: 0px;
    margin-bottom: 0px;
    min-height: unset;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const LeftAligned = styled(motion.div)`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ParagraphTextContainer = styled.div`
  margin-left: 0;
  @media (max-width: 1050px) {
    margin-left: 5%;
    padding-right: 20px;
  }
  @media (max-width: 800px) {
    margin-left: 0%;
    margin-right: 0%;
  }
`;

const RightAligned = styled(motion.div)`
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
    margin-top: 30px;
  }
`;

const ParagraphTitle = styled.h2`
  font-weight: 400;
  font-size: 40px;
  color: #404040;
  max-width: 500px;
  font-weight: 400;
  font-style: normal;
  font-family: DM Serif Text;
  @media (max-width: 800px) {
    font-size: 26px;
    line-height: 30px;
  }
`;

const ParagraphText = styled.div`
  color: #404040;
  // font-weight: 500;
  // font-size: 18px;
  line-height: 1.6;
  letter-spacing: 0.01em;
  max-width: 450px;

  ul {
    list-style: outside;
    padding-left: 20px;
    list-style-type: square;
  }

  strong {
    font-weight: bold;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 800px) {
    height: 330px;
  }
`;

const ImageContainerInner = styled.div`
  width: 100%;
  height: 630px;
  @media (max-width: 800px) {
    width: 100%;
    height: 330px;
  }
`;

const SquiggleContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: -150px;
  transform: rotate(45deg);
  @media (max-width: 1050px) {
    display: none;
  }
`;

const rightAnim = {
  hidden: { opacity: 0, translateX: 50 },
  visible: {
    opacity: 1,
    translateX: 0,
    transition: {
      delay: 0.15,
    },
  },
};

const leftAnim = {
  hidden: { opacity: 0, translateX: -50 },
  visible: {
    opacity: 1,
    translateX: 0,
  },
};

export class leftTextRightImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={350}
      >
        <TextContainerOuter>
          <TextContainerInner>
            <TextContainer>
              <LeftAligned
                variants={leftAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                <ParagraphTextContainer>
                  {this.props.label_title && (
                    <Label text={this.props.label_title} color="#4758eb" />
                  )}
                  <BlankSpace height="30px" />
                  <ParagraphTitle>{this.props.title_text}</ParagraphTitle>
                  <BlankSpace height="30px" />
                  <ParagraphText>{this.props.paragraph_text}</ParagraphText>
                </ParagraphTextContainer>
              </LeftAligned>
              <RightAligned
                variants={rightAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                <ImageContainer>
                  <ImageContainerInner>
                    {this.props.right_aligned_image && (
                      <LazyImage {...this.props.right_aligned_image} />
                    )}
                  </ImageContainerInner>
                </ImageContainer>
              </RightAligned>
            </TextContainer>
          </TextContainerInner>
        </TextContainerOuter>
      </VisibilitySensor>
    );
  }
}

export default leftTextRightImage;
