import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

//Import Components
import TitleUnderlined from '../components/TitleUnderlined';
import HeaderSubtitle from '../components/HeaderSubtitle';
import ButtonUnderlined from '../components/ButtonUnderlined';
import LazyImage from '../components/lazy-image';
import Squiggle from '../components/squiggle';
import linkStripper from '../utils/link-stripper';

//Import assets
import backgroundImageHero from '../images/tmp/expertiseHeader.png';
import HeaderSquiggle from '../images/squiggles/headerSquiggle.svg';

const TitleUnderlinedContainer = styled.h1`
  font-size: 58px;
  font-family: 'DM Serif Text', sans-serif;
  font-weight: 400;
  color: ${props => props.textColor};
  width: fit-content;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 2.5px;
  overflow: hidden;
  background: linear-gradient(
    to top,
    ${props => props.underlineColor} 40%,
    transparent 20%
  );
  @media (max-width: 700px) {
    font-size: 35px;
    line-height: 38px;
  }
`;

const HeaderContainer = styled(motion.div)`
  display: flex;
  // height: calc(100vh - 160px);
  // max-height: 1200px;
  padding-bottom: 50px;
  @media (max-width: 800px) {
    flex-wrap: wrap;
    height: unset;
  }
`;

const LeftAlignedSection = styled(motion.div)`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10%;
  padding-right: 30px;

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 150px;
    padding-left: 5%;
    padding-right: 0;
  }
`;

const RightAlignedSection = styled(motion.div)`
  width: 50%;
  background-color: #eee;
  position: relative;
  @media (max-width: 800px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    height: 225px;
    margin-top: 50px;
  }
`;

const SquiggleContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  margin-left: -250px;
  margin-bottom: -75px;
  mix-blend-mode: multiply;
  @media (max-width: 800px) {
    display: none;
  }
`;

const HeaderSubtitleContainer = styled(motion.div)`
  div {
    max-width: 600px;
  }
`;

const ButtonContainer = styled(motion.div)``;

const TitleUnderlinedOuter = styled(motion.div)`
  max-width: 600px;
  @media (max-width: 800px) {
    max-width: 100%;
  }
`;

{
  /*const BackgroundImage = styled(LazyImage)`
  width: 100%;
  height: auto;
  `;*/
}

const CaseStudyHero = ({
  props,
  title,
  title_underline_color,
  subtitle_text,
  call_to_action_text,
  call_to_action_text_colour,
  call_to_action_arrow_colour,
  cta_link,
  hero_image,
  squiggle_color,
  cta_link_target,
}) => {
  return (
    <HeaderContainer>
      <LeftAlignedSection>
        <div>
          <TitleUnderlinedOuter
            animate={{ opacity: 1, translateX: 0 }}
            initial={{ opacity: 0, translateX: -50 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <TitleUnderlined
              underlineColor={title_underline_color}
              titleText={'<span>' + title + '</span>'}
              fontSize="58px"
              mobileFontSize={'38px'}
              mobileLineHeight={'42px'}
              tabletFontSize={'52px'}
              tableLineHeight={'56px'}
              leftAligned={true}
              h1Mode={true}
            />
          </TitleUnderlinedOuter>
          <HeaderSubtitleContainer
            animate={{ opacity: 1, translateX: 0 }}
            initial={{ opacity: 0, translateX: -50 }}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            <HeaderSubtitle subtitle_text={subtitle_text} />
          </HeaderSubtitleContainer>
          <ButtonContainer
            animate={{ opacity: 1, translateX: 0 }}
            initial={{ opacity: 0, translateX: -50 }}
            transition={{ duration: 0.9, delay: 0.3 }}
          >
            <ButtonUnderlined
              margin={'40px 0px 0px 0px'}
              buttonText={call_to_action_text}
              color={call_to_action_text_colour}
              arrowColor={
                call_to_action_arrow_colour
                  ? call_to_action_arrow_colour
                  : call_to_action_text_colour
              }
              link={linkStripper(cta_link.url)}
              target={cta_link_target}
            />
          </ButtonContainer>
        </div>
      </LeftAlignedSection>
      <RightAlignedSection
        animate={{ opacity: 1, translateX: 0 }}
        initial={{ opacity: 0, translateX: 50 }}
        transition={{ duration: 0.7, delay: 0.4 }}
        style={{
          backgroundImage: `url(${hero_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/*<BackgroundImage fluid={image.childImageSharp.fluid} />*/}
      </RightAlignedSection>
    </HeaderContainer>
  );
};

export default CaseStudyHero;
